<template>
  <div>
    <div class="vx-row mb-2" style="width: 100%">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Code : </span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">{{ selected.code }}</div>
      <div class="vx-col sm:w-1/3 w-full">
        <span>Customer Name : </span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">{{ selected.name }}</div>
      <div class="vx-col sm:w-1/3 w-full">
        <span>Credit Limit Amount : </span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <feather-icon
          v-if="selected.amount < 0"
          icon="MinusIcon"
          svgClasses="h-3 w-3"
          style="color: red; font-weight: bold"
        ></feather-icon>
        {{ priceFormat(selected.amount) }}
      </div>
      <div class="vx-col sm:w-1/3 w-full">
        <span>Credit Limit Used : </span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        {{ creditLimitUsed }}%
      </div>
      <div class="vx-col sm:w-1/3 w-full">
        <span>Receivables : </span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <feather-icon
          v-if="receivable < 0"
          icon="MinusIcon"
          svgClasses="h-3 w-3"
          style="color: red; font-weight: bold"
        ></feather-icon>
        {{ priceFormat(receivable) }}
      </div>
      <div class="vx-col sm:w-1/3 w-full">
        <span>Sales Value : </span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <feather-icon
          v-if="salesValue < 0"
          icon="MinusIcon"
          svgClasses="h-3 w-3"
          style="color: red; font-weight: bold"
        ></feather-icon>
        {{ priceFormat(salesValue) }}
      </div>
      <div class="vx-col sm:w-1/3 w-full">
        <span>Exposure : </span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        {{ priceFormat(usedAmount) }}
      </div>
      <div class="vx-col sm:w-1/3 w-full">
        <span>Risk Category : </span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        ({{ riskCategory.code }}) {{ riskCategory.description }}
      </div>
    </div>

    <br>
    <div>
      <data-table
        :responseData="responseData"
        :propsParams="params"
        :header="header"
        @reloadDataFromChild="reloadData"
      >
        <template slot="tbody">
          <vs-tr :key="indextr" v-for="(tr, indextr) in transactions">
            <vs-td style="align-item-left">{{dateFormat(tr.CreatedAt)}}</vs-td>
            <vs-td style="align-item-center">{{ tr.trx_code }}</vs-td>
            <vs-td style="align-item-center">{{ tr.name }}</vs-td>
            <vs-td style="align-item-right">
              <feather-icon
                v-if="tr.type == 'k'"
                icon="PlusIcon"
                svgClasses="h-5 w-5"
                style="color: green; font-weight: bold"
              ></feather-icon>
              <feather-icon
                v-else
                icon="MinusIcon"
                svgClasses="h-5 w-5"
                style="color: red; font-weight: bold"
              ></feather-icon>
              <br />{{ priceFormat(tr.amount) }}</vs-td
            >
            <vs-td style="align-item-right">
              <feather-icon
                v-if="tr.current_balance < 0"
                icon="MinusIcon"
                svgClasses="h-5 w-5"
                style="color: red; font-weight: bold"
              ></feather-icon>
              <feather-icon
                v-else
                icon="PlusIcon"
                svgClasses="h-5 w-5"
                style="color: green; font-weight: bold"
              ></feather-icon>
              <br />{{ priceFormat(tr.current_balance) }}
            </vs-td>
          </vs-tr>
        </template>
      </data-table>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  components: {},
  props: {
    dataId:Number,
    selected: {},
  },
  // created() {
  // },
  // mounted() {
  // },
  watch: {
    dataId() {
      this.transactions = [];
      this.reloadData(this.params);
      console.log('The dataId has changed!', this.selected)
    },
  },
  mounted() {
    this.reloadData(this.params);
  },
  data: () => ({
    params: {
      search: "",
      length: 10,
      page: 1,
      order: "asc",
      sort: "id",
    },
    header: [
      {
        text: "Date",
        value: "date",
        width: '15%'
      },
      {
        text: "Code",
        value: "trx_code",
        // width: '5%'
      },
      {
        text: "Name",
        value: "name",
        // width: '5%'
      },
      {
        text: "Amount",
        value: "amount",
        // width: '5%'
      },
      {
        text: "Credit Balance",
        // value: "amount",
        // width: '5%'
      },
    ],
    responseData: {},
    transactions: [],
    riskCategory: {},
    usedAmount: 0,
    receivable: 0,
    salesValue: 0,
    creditLimitUsed: 0,
  }),
  methods: {
    dateFormat(date){
      return moment.utc(date).format('DD-MM-YYYY HH:mm')
    },
    reloadData(params) {
      console.log("sip");
      this.params = params;
      this.$vs.loading();
      this.$http
        .get("api/v1/report/customer-limit/" + this.dataId, {
          params: {
            search: this.params.search,
            length: this.params.length,
            page: this.params.page,
            order: this.params.order,
            sort: this.params.sort,
          },
        })
        .then((resp) => {
          console.log("resp.data, ", resp.data);
          if (resp.code == 200) {
            this.responseData = resp.data;
            this.riskCategory = resp.data.riskCategory;
            this.receivable = resp.data.totalInvoice;
            if (resp.data.creditLimit.used != null) {
              this.usedAmount = resp.data.creditLimit.used;
            }
            this.salesValue = this.usedAmount - this.receivable;
            if (this.usedAmount && this.selected && this.selected.amount) {
              this.creditLimitUsed = ((this.usedAmount / this.selected.amount) * 100).toFixed(2);
            } else {
              this.creditLimitUsed = 0; // or handle appropriately
            }
            this.transactions = resp.data.transaction
            // this.transactions = [];
            // let creditBalanceRemaining = this.selected.amount
            // for (let i = 0; i < resp.data.transaction.length; i++) {
            //   console.log("index: " + i, "credit");
            //   const transaction = {...resp.data.transaction[i]}

              // let creditBalance = 0
              // console.log("awal index "+i, creditBalanceRemaining);
              // if (resp.data.transaction[i].type == "k") {
              //   creditBalance = creditBalanceRemaining + transaction.amount
              //   creditBalanceRemaining += transaction.amount
              // } else {
              //   creditBalance = creditBalanceRemaining - transaction.amount
              //   creditBalanceRemaining -= transaction.amount
              // }
              // transaction.creditBalance = creditBalance
              // console.log("index "+i, transaction.amount);
              // console.log("index "+i, transaction.creditBalance);
              // console.log("akhir index "+i, creditBalanceRemaining);

              // console.log("data index: " + i, transaction);
              // this.transactions.push(transaction)
            // }

            // this.transactions.reverse();
          }
          this.$vs.loading.close();
        });
    },
  },
};
</script>

<style scoped>
.margin-btm {
  margin-bottom: 1rem !important;
}
.vs-input--input.normal {
  text-align: end;
}
</style>